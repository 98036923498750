import React from 'react';
import styles from '../styles/SectionTwo.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Leadership = () => {
  return (
    <Container fluid className={styles.sectionTwoWrapper} id='investment-focus'>
      <Row className={styles.tealBanner} />
      <Container className={styles.sectionTwoContent}>
        <h2 className={'ghi white-text text-align-center'}>
          Leadership In Digital Health Investing
        </h2>
        <Row className={styles.sectionTwoStats}>
          <Row>
            <Col className={styles.statWrapper} lg={4} sm={12}>
              <p className={styles.statNumber}>$600M</p>
              <p className={styles.statText}>Evergreen Fund</p>
            </Col>
            <Col className={styles.statWrapper} lg={4} sm={12}>
              <p className={styles.statNumber}>2010</p>
              <p className={styles.statText}>Year Founded</p>
            </Col>
            <Col className={styles.statWrapper} lg={4} sm={12}>
              <p className={styles.statNumber}>70+</p>
              <p className={styles.statText}>Investments</p>
            </Col>
          </Row>
        </Row>
        <Row className={styles.sectionTwoAreas}>
          <h2 className={`ghi ${styles.areasHeader}`}>
            Partnering With Innovators From Early to Growth Stage
          </h2>
          <Row className={styles.areasWrapper}>
            <Col lg={4} sm={12}>
            <h2 className='ghi'>Accelerators</h2>
              <p>
              GHI invests up to $2M in early-stage companies through our three Accelerators:  <a
            href='https://www.mds.studio/'
            target='_blank'
            rel='noreferrer'
            aria-label='Opens in a new tab.'
          >
            MSD Digital Science Studio
          </a>{' '}
          , MSD IDEA Studios Singapore, MSD IDEA Studios Berlin 

              </p>
              <p className={styles.tealBox}>
                Early stage / Agnostic
                <br />
                Up to 20% ownership
              </p>
            </Col>
            <Col lg={4} sm={12}>
              <h2 className='ghi'>Venture Capital</h2>
              <p>
                GHI typically invests up to $10M equity in areas aligned with
                business needs
              </p>
              <p className={styles.tealBox}>
                Series B or Series C<br />
                Up to 20% ownership
              </p>
            </Col>
            <Col lg={4} sm={12}>
              <h2 className='ghi'>Growth Capital</h2>
              <p>
                GHI invests more than $10M equity when business is signing
                multiyear multimillion services contract
              </p>
              <p className={styles.tealBox}>
                Growth Capital
                <br />
                Up to 30% ownership
              </p>
            </Col>
          </Row>
          <div className={styles.bottomTealBox}>
            <p className='white-text ghi'>
              Investing globally, with a focus on North America and Europe
            </p>
          </div>
        </Row>
      </Container>
    </Container>
  );
};

export default Leadership;
